.float-label {
    position: relative;
  }
  
  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 11px;
    transition: 0.2s ease all;
  }
  
  .as-placeholder {
    color: gray;
  }
  
  .as-label {
    top: -8px;
    font-size: 12px !important;
    background: white;
    padding: 0 4px;
    margin-left: -4px;
  }
  .ant-input:focus, .ant-input-focused {
    border-color: #F6A41C !important;
}
.ant-input:focus + .as-label,
.ant-input-focused + .as-label {
    color: #F6A41C
}